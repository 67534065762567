import(/* webpackMode: "eager" */ "/Users/moshood/amoshood/jenifa-gardens-website/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/Users/moshood/amoshood/jenifa-gardens-website/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/Users/moshood/amoshood/jenifa-gardens-website/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Playfair_Display\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--serif\",\"display\":\"swap\",\"preload\":true,\"adjustFontFallback\":true}],\"variableName\":\"serif\"}");
;
import(/* webpackMode: "eager" */ "/Users/moshood/amoshood/jenifa-gardens-website/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"DM_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--sans\",\"display\":\"swap\",\"preload\":true,\"adjustFontFallback\":true}],\"variableName\":\"sans\"}");
;
import(/* webpackMode: "eager" */ "/Users/moshood/amoshood/jenifa-gardens-website/public/assets/images/jenifa-touch-image.png");
;
import(/* webpackMode: "eager" */ "/Users/moshood/amoshood/jenifa-gardens-website/public/assets/images/logo.png");
;
import(/* webpackMode: "eager" */ "/Users/moshood/amoshood/jenifa-gardens-website/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/Users/moshood/amoshood/jenifa-gardens-website/src/components/navbar.tsx");
