"use client";

import { cn } from "@/lib/utils";
import Link from "next/link";
import { usePathname } from "next/navigation";
import {
  ReactNode
} from "react";

type NavLinkProps = {
  href: string;
  label: ReactNode;
};

const isPathMatched = (pathname: string, route: string) =>
  route === pathname || (route.length > 2 && pathname.includes(route));

export default function NavLink({ href, label }: NavLinkProps) {
  const pathname = usePathname();
  const isActive = isPathMatched(pathname, href);
  const handleLinkClick = () => {};

  return (
    <Link
      href={href}
      onClick={handleLinkClick}
      className={cn(
        "flex font-medium text-white items-center text-sm md:text-base py-1 px-3 md:px-6 rounded-lg transition-all",
        isActive && "text-slate-700 bg-brand-secondary"
      )}
    >
      {label}
    </Link>
  );
}
