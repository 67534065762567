"use client";

import { createContext } from "react";
// import NavLink from "../nav-link";
import { Routes } from "@/app/routes";
import { cn } from "@/lib/utils";
import NavLink from "./nav-link";

type MobileMenuContextType = {
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: (isOpen: boolean) => void;
};

export const MobileMenuContext = createContext<MobileMenuContextType>({
  isMobileMenuOpen: false,
  setIsMobileMenuOpen: () => {},
});

export function Navbar() {
  return (
    <nav className={cn("hidden md:flex gap-2 items-center ml-auto")}>
      <NavLink href={Routes.index()} label="Home" />

      <NavLink href={Routes.faqs()} label="FAQs" />

      <NavLink href={Routes.contactUs()} label="Contact us" />
    </nav>
  );
}
